import { default as consort_45differenceDLIa5Wcom8Meta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/consort-difference.vue?macro=true";
import { default as indexW8lypcsnDLMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue?macro=true";
import { default as indexmfPqVevSJrMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue?macro=true";
import { default as indexdMcaj2mqvQMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue?macro=true";
import { default as indexgSK0OWtYWPMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/events/index.vue?macro=true";
import { default as our_45processKfutiogvUsMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-process.vue?macro=true";
import { default as our_45storydGfW2jEclrMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-story.vue?macro=true";
import { default as our_45teamkrfK6ubOUMMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-team.vue?macro=true";
import { default as our_45warranty0KLqg9nXh7Meta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-warranty.vue?macro=true";
import { default as why_45consortmFqqg6FaHPMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/why-consort.vue?macro=true";
import { default as _91post_938IFJdtHCHKMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexIdn1zXM8jeMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexIYImu010UzMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as callbackiLNosVk52DMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_93nPcStwTBlZMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as index5TbSoerCMvMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as career_45opportunitieskZjxG5xZtGMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as contact_45usb2iaPItLqVMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue?macro=true";
import { default as for_45realtorsDsfYAw5MVYMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue?macro=true";
import { default as our_45locationsfz0Dn6kTl9Meta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as request_45an_45appointmentTP0oXr9lovMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue?macro=true";
import { default as join_45vip_45listBQaMH850F4Meta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue?macro=true";
import { default as why_45buy_45nowwY9PGaM8xcMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue?macro=true";
import { default as index4uwtWCPAGqMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue?macro=true";
import { default as indexSpnuMv61FeMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue?macro=true";
import { default as indexMDof4UnvlxMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue?macro=true";
import { default as indexGRxjZXAk4OMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue?macro=true";
import { default as indextI0zqbgBkTMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue?macro=true";
import { default as indexbWvvnZKRNzMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/video/index.vue?macro=true";
import { default as indexRRBO3UxZPfMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue?macro=true";
import { default as index8n56EiaiuuMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/index.vue?macro=true";
import { default as interactive_45home_45designEPAxsMKXPuMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/interactive-home-design.vue?macro=true";
import { default as my_45favoritesOeEkeJb7yoMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as indexLf0w1t0JYgMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue?macro=true";
import { default as indexH2pIwwyO1DMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue?macro=true";
import { default as indexxEglxw3NDkMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue?macro=true";
import { default as build_45on_45your_45lotea2DKEdD6kMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue?macro=true";
import { default as design_45processAU7b2Mg7ldMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue?macro=true";
import { default as financingWgXaqlbivkMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/financing.vue?macro=true";
import { default as indexQK1xe8phWeMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacy_45policyfEmfDNAzUBMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_933AObkxjf1uMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue?macro=true";
import { default as index1uRcN19zFLMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/promotions/index.vue?macro=true";
import { default as sandbox9IZy5GlHnrMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as self_45guided_45home_45toursovQRpWYWcWMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue?macro=true";
import { default as _91testimonial_93OzfssKDdQUMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue?macro=true";
import { default as where_45we_45buildrtdpfYIPHzMeta } from "/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/where-we-build.vue?macro=true";
export default [
  {
    name: "about-consort-difference",
    path: "/about/consort-difference",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/consort-difference.vue")
  },
  {
    name: "about-consort-homes-reviews-and-testimonials-category-category",
    path: "/about/consort-homes-reviews-and-testimonials/category/:category()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue")
  },
  {
    name: "about-consort-homes-reviews-and-testimonials",
    path: "/about/consort-homes-reviews-and-testimonials",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue")
  },
  {
    name: "about-events-event",
    path: "/about/events/:event()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue")
  },
  {
    name: "about-events",
    path: "/about/events",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/events/index.vue")
  },
  {
    name: "about-our-process",
    path: "/about/our-process",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-process.vue")
  },
  {
    name: "about-our-story",
    path: "/about/our-story",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-story.vue")
  },
  {
    name: "about-our-team",
    path: "/about/our-team",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-team.vue")
  },
  {
    name: "about-our-warranty",
    path: "/about/our-warranty",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/our-warranty.vue")
  },
  {
    name: "about-why-consort",
    path: "/about/why-consort",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/about/why-consort.vue")
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/blog/[post].vue")
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/blog/category/[category]/index.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/blog/index.vue")
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/callback.vue")
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93nPcStwTBlZMeta || {},
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/campaigns/index.vue")
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue")
  },
  {
    name: "contact-us-contact-us",
    path: "/contact-us/contact-us",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue")
  },
  {
    name: "contact-us-for-realtors",
    path: "/contact-us/for-realtors",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue")
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue")
  },
  {
    name: "contact-us-request-an-appointment",
    path: "/contact-us/request-an-appointment",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue")
  },
  {
    name: "current-offers-join-vip-list",
    path: "/current-offers/join-vip-list",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue")
  },
  {
    name: "current-offers-why-buy-now",
    path: "/current-offers/why-buy-now",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue")
  },
  {
    name: "display-homes-model",
    path: "/display-homes/:model()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue")
  },
  {
    name: "gallery-heritage-gallery",
    path: "/gallery/heritage-gallery",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue")
  },
  {
    name: "gallery-hometown-gallery",
    path: "/gallery/hometown-gallery",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue")
  },
  {
    name: "gallery-photos",
    path: "/gallery/photos",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue")
  },
  {
    name: "gallery-product-gallery",
    path: "/gallery/product-gallery",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue")
  },
  {
    name: "gallery-video",
    path: "/gallery/video",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/video/index.vue")
  },
  {
    name: "gallery-virtual-tours",
    path: "/gallery/virtual-tours",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/index.vue")
  },
  {
    name: "interactive-home-design",
    path: "/interactive-home-design",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/interactive-home-design.vue")
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/my-favorites.vue")
  },
  {
    name: "new-homes-homes-home",
    path: "/new-homes/homes/:home()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue")
  },
  {
    name: "new-homes-plans-plan",
    path: "/new-homes/plans/:plan()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue")
  },
  {
    name: "new-neighborhoods-location-community",
    path: "/new-neighborhoods/:location()/:community()",
    meta: indexxEglxw3NDkMeta || {},
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue")
  },
  {
    name: "our-homes-build-on-your-lot",
    path: "/our-homes/build-on-your-lot",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue")
  },
  {
    name: "our-homes-design-process",
    path: "/our-homes/design-process",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue")
  },
  {
    name: "our-homes-financing",
    path: "/our-homes/financing",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/financing.vue")
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/our-homes/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/privacy-policy.vue")
  },
  {
    name: "promotions-slug",
    path: "/promotions/:slug(.*)*",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/promotions/index.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/sandbox.vue")
  },
  {
    name: "self-guided-home-tours",
    path: "/self-guided-home-tours",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue")
  },
  {
    name: "testimonials-testimonial",
    path: "/testimonials/:testimonial()",
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue")
  },
  {
    name: "where-we-build",
    path: "/where-we-build",
    meta: where_45we_45buildrtdpfYIPHzMeta || {},
    alias: ["/display-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src3965253617/src/consort-headless/nuxtapp/pages/where-we-build.vue")
  }
]